import React, { useState } from "react";
import { useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";

import { loadingState } from "../../../../recoil/state";
import usePopup from "../../../../hooks/usePopup";
import useGetData from "../../../../hooks/useGetData";
import usePutData from "../../../../hooks/usePutData";
import useSnackbar from "../../../../hooks/useSnackbar";

import DemoteIcon from '../../../../assets/icons/DemoteIcon'
import TransferIcon from '../../../../assets/icons/TransferIcon'
import StudentIcon from '../../../../assets/icons/StudentIcon.svg'
import CustomTable from "../../../../components/mui/Table";
import InputPopup from "../../../../components/popups/globalPopup/InputPopup";
import { educationYears } from "../../../../utils/dateFormat";
import RenderStats from "../../../../utils/RenderStats";
import { convertArrayCamelCaseToFirstWords } from "../../../../utils/functions";
import BlinkingBadge from "../../../../components/mui/BlinkingBadge ";

const EnrolledStudents = () => {
    const { data: enrollData, isLoading: enrollDataLoading, updateData } = useGetData('/api/employee/admission/enrolleds/getEnrolleds')
    const { data: statsData, isLoading: statsLoading } = useGetData('/api/employee/admission/enrolleds/stats/getStudentsProgramsCount');
    const { putData } = usePutData();

    const navigate = useNavigate();
    const { openPopup } = usePopup();
    const { handleSnackbarOpen } = useSnackbar();
    const setLoading = useSetRecoilState(loadingState);

    const [demotePopup, setDemotePopup] = useState({ isOpen: false, _id: '' });
    const [transferPopup, setTransferPopup] = useState({ isOpen: false, _id: '' });

    const handleRow = (event, value) =>
        openPopup('View Details', 'Are you sure you want to view further details?', 'view', () => navigate("/admin/student/enrolleds/profile/" + value + '?type=Personal'));

    const handleDemote = (event, value) => setDemotePopup({ isOpen: true, _id: value });

    const onDemote = async (formData) => {
        setDemotePopup({ isOpen: false, _id: '' });
        setLoading(true);
        const { success, message } = await putData(`/api/employee/admission/enrolleds/updateEnrolledDemotion/${demotePopup?._id}`, formData);
        setLoading(false);

        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen('Opps... some error occurred!', 'error');
        updateData(prevState => {
            return prevState.filter(user => user._id !== demotePopup?._id);
        });
        handleSnackbarOpen(`Student successfully Demoted!`, 'success');
    }

    const displayStatus = (event, id) => {
        const user = enrollData?.find(user => user._id === id) || {};
        if (!user?.pendingVerification) return handleRow(null, user?._id);
        const verificationStatus = convertArrayCamelCaseToFirstWords(user?.pendingVerification);
        return handleSnackbarOpen(`${verificationStatus?.join(', ')} Verification is pending for Student.`, 'warning');
    };

    const handleCompletion = (event, value) => setTransferPopup({ isOpen: true, _id: value });

    const onCompletion = async (formData) => {
        setTransferPopup({ isOpen: false, _id: '' })
        setLoading(true);
        const { success, message } = await putData(`/api/employee/admission/enrolleds/updateEnrolledTransfer/${transferPopup._id}`, formData);
        setLoading(false);

        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen('Opps... some error occurred!', 'error');
        updateData(prevState => {
            return prevState.filter(user => user._id !== transferPopup._id);
        });
        handleSnackbarOpen(`Student successfully Transferred!`, 'success');
    }

    const columns = [
        { id: 'srno', label: 'Sr.No', minWidth: 100, type: 'text' },
        {
            id: 'name', label: 'Student Name', minWidth: 200, type: 'iconButton',
            align: 'center', onClick: displayStatus,
            render: (row) => (
                row?.pendingVerification ?
                    <BlinkingBadge sx={{
                        '& .MuiBadge-anchorOriginTopLeftCircular': {
                            top: '0px',
                            left: '-8px',
                        },
                    }}
                        position={{ vertical: 'top', horizontal: 'left' }}
                        active={row?.pendingVerification} badgeColor='#F97316'>
                        {row?.name}
                    </BlinkingBadge> : <p> {row?.name} </p>
            )
        },
        { id: 'emailId', label: 'Email ID', minWidth: 200, type: 'text' },
        { id: 'phNo', label: 'Contact No.', minWidth: 200, type: 'text' },
        { id: 'admissionYear', label: 'Admission Year', minWidth: 180, type: 'text' },
        { id: 'programKey', label: 'Program Key', minWidth: 180, type: 'text' },
        {
            id: 'demote', label: 'Transfer to Applicant', type: 'iconButton',
            align: 'center', onClick: handleDemote,
            render: (row) => (
                <div className="flex items-center justify-center">
                    <DemoteIcon className="h-6 w-6 fill-purple-700" />
                </div>
            )
        },
        {
            id: 'completion', label: 'Transfer to Alumni', type: 'iconButton',
            align: 'center', onClick: handleCompletion,
            render: (row) => (
                <div className="flex items-center justify-center">
                    <TransferIcon className="h-8 w-8 fill-purple-700" />
                </div>
            )
        },
    ];

    const filterOptions = [
        { label: "N/A", value: "N/A" },
    ]

    const transferFields = [
        {
            type: 'select',
            name: 'graduationYear',
            label: 'Graduation Year',
            options: educationYears
        }
    ]

    const demoteFields = [
        {
            type: 'input',
            name: 'statusMessage',
            label: 'Remark',
            rows: 4,
            multiline: true,
            placeholder: 'Add a remark'
        }
    ]

    return (
        <div className='flex flex-col gap-8 bg-white p-5 min-h-[calc(100svh-5rem)]'>
            <RenderStats icon={StudentIcon} data={statsData} statsLoading={statsLoading} />

            <CustomTable
                heading="All Enrolled Students"
                text="Enrolled Students data"
                isLoading={enrollDataLoading}
                columns={columns} rows={enrollData}
                sortParameters={['name', 'admissionYear', 'programKey']}
                filterOptions={filterOptions}
                handleRowClick={handleRow}
                activeOperations
            />

            {demotePopup?.isOpen && <InputPopup fields={demoteFields} icon='edit' heading='Demote to Applicant' subtext='Are you sure you want to demote this Student to Alumni? If the college is using the CAMPUS ERP system, the student&apos;s access will also be terminated from it.' onClose={() => setDemotePopup({ isOpen: false, _id: '' })} onConfirm={onDemote} />}

            {transferPopup?.isOpen && <InputPopup fields={transferFields} icon='edit' heading='Transfer to Alumni' subtext='Are you sure you want to transfer this student to Alumni?' onClose={() => setTransferPopup({ isOpen: false, _id: '' })} onConfirm={onCompletion} />}
        </div>
    )
}

export default EnrolledStudents;