import React, { useCallback } from 'react';
import useGetData from '../../../../hooks/useGetData';
import { CustomAsyncSelect } from '../../../../components/mui/Input';
import { academicYears } from '../../../../utils/dateFormat';
import StudentIcon from '../../../../assets/icons/StudentIcon.svg';
import ProfessorIcon from '../../../../assets/icons/ProfessorIcon.svg';
import { useSearchParams } from 'react-router-dom';
import { SquareIconButton } from '../../../../components/mui/button/SquareIconButton';
import { FilterAltOff } from '@mui/icons-material';
import OrganizationStats from './OrganizationStats';

const FilterComponent = ({ organizationStats, isLoading }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const handleChange = useCallback((name, value) => {
        const newParams = new URLSearchParams(searchParams);

        if (value) newParams.set(name, value);
        else newParams.delete(name);

        // Implement hierarchy logic
        if (name === 'division_id') {
            newParams.delete('department_id');
            newParams.delete('program_id');
        } else if (name === 'department_id') {
            newParams.delete('program_id');
        }

        setSearchParams(newParams);
    }, [searchParams, setSearchParams]);

    const { data: divisionData, isLoading: divisionDataLoading } = useGetData('/api/organization/getDivisions');
    const divisionId = searchParams.get('division_id');
    const departmentId = searchParams.get('department_id');

    const { data: departmentData, isLoading: departmentDataLoading } = useGetData(`/api/organization/getDepartments?division_id=${divisionId}`, null, null, !!divisionId);
    const { data: programData, isLoading: programDataLoading } = useGetData(`/api/organization/getPrograms?department_id=${departmentId}`, null, null, !!departmentId);

    const { data: studentCounts, isLoading: studentCountsLoading } = useGetData(
        `/api/organization/getStudentCounts?${searchParams.toString()}`
    );

    const { data: employeeCounts, isLoading: employeeCountsLoading } = useGetData(
        `/api/organization/getEmployeeCounts?${searchParams.toString()}`
    );

    const divisionOptions = divisionData?.map((division) => ({ label: division?.name, value: division?._id })) || [];

    const departmentOptions = departmentData?.map((department) => ({ label: department?.name, value: department?._id })) || [];

    const programOptions = programData?.map((program) => ({ label: program?.name, value: program?._id })) || [];

    const academicYearsOptions = academicYears().map((year) => ({ label: year, value: year }))

    const studentStats = [
        { heading: 'Total Applicant', count: studentCounts?.newAdmissionCount, icon: StudentIcon },
        { heading: 'Total Enrolled Students', count: studentCounts?.enrolledCount, icon: StudentIcon },
        { heading: 'Total Alumni', count: studentCounts?.alumniCount, icon: StudentIcon }
    ]
    const employeeStats = [
        { heading: 'Total Recruits', count: employeeCounts?.newAdmissionCount, icon: ProfessorIcon },
        { heading: 'Total Current Employees', count: employeeCounts?.currentCount, icon: ProfessorIcon },
        { heading: 'Total Former Employees', count: employeeCounts?.formerCount, icon: ProfessorIcon }
    ]

    const clearFilter = useCallback(() => {
        const newParams = new URLSearchParams(searchParams);

        newParams.delete('division_id');
        newParams.delete('department_id');
        newParams.delete('program_id');
        newParams.delete('year');

        setSearchParams(newParams);
    }, [searchParams, setSearchParams]);


    const statsData = { organizationStats, isLoading, studentStats, studentCountsLoading, employeeStats, employeeCountsLoading };

    return (
        <div className='flex flex-col gap-5'>
            <div className='grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-16 gap-4 xs:gap-5 p-5 border drop-shadow bg-white rounded-[4px]'>
                <CustomAsyncSelect
                    className='lg:col-span-4 h-fit'
                    label='Division'
                    name='division_id'
                    value={searchParams.get('division_id') || ''}
                    onChange={(e) => handleChange('division_id', e.target.value)}
                    options={divisionOptions}
                    skeletonLoading={divisionDataLoading}
                    skeletonClassName='lg:col-span-4'
                />

                <CustomAsyncSelect
                    className='lg:col-span-4 h-fit'
                    label='Department'
                    name='department_id'
                    value={searchParams.get('department_id') || ''}
                    onChange={(e) => handleChange('department_id', e.target.value)}
                    options={departmentOptions}
                    noOptionsMessage='Select Division first'
                    loading={departmentDataLoading}
                    skeletonLoading={divisionDataLoading}
                    skeletonClassName='lg:col-span-4'
                />

                <CustomAsyncSelect
                    className='lg:col-span-4 h-fit'
                    label='Program'
                    name='program_id'
                    value={searchParams.get('program_id') || ''}
                    onChange={(e) => handleChange('program_id', e.target.value)}
                    options={programOptions}
                    noOptionsMessage='Select Department first'
                    loading={programDataLoading}
                    skeletonLoading={divisionDataLoading}
                    skeletonClassName='lg:col-span-4'
                />

                <CustomAsyncSelect
                    className='lg:col-span-3 h-fit'
                    label='Academic Year'
                    name='year'
                    value={searchParams.get('year') || ''}
                    onChange={(e) => handleChange('year', e.target.value)}
                    options={academicYearsOptions}
                    skeletonLoading={divisionDataLoading}
                    skeletonClassName='lg:col-span-3'
                />

                <SquareIconButton title='Clear Filter' sx={{ border: 1, borderColor: '#bfbcc2' }} icon={<FilterAltOff sx={{ color: "red", fontSize: 24 }} />} onClick={clearFilter} />
            </div>

            <OrganizationStats statsData={statsData} />
        </div>
    );
};

export default FilterComponent;