export const dateFormat = (dateString, showTime = false, defaultValue = 'N/A') => {
    const options = {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
    };

    if (showTime) {
        options.hour = '2-digit';
        options.minute = '2-digit';
        options.second = '2-digit';
        options.hour12 = true;
    }

    return dateString ? new Date(dateString).toLocaleString('en-US', options) : defaultValue;
};

export const formatTime = seconds => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
};

export const academicYears = (startYear = 1980) => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: currentYear - startYear }, (_, i) => {
        const year = currentYear - i;
        return `${year} - ${year - 1}`;
    });
}

export const educationYears = Array.from({ length: 75 }, (_, i) => {
    const year = new Date().getFullYear() - i;
    return { value: year.toString(), label: `${year}-${(year + 1) % 100}` };
});

export const calculateExperience = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (end < start) return 'Invalid Experience';

    let diffInMs = end - start;
    const years = Math.floor(diffInMs / (1000 * 60 * 60 * 24 * 365));
    diffInMs -= years * 1000 * 60 * 60 * 24 * 365;
    const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));

    return `${(years > 0) ? `${years} ${years > 1 ? 'years  ' : 'year  '}` : ''}${(days > 0) ? `${days} ${days > 1 ? 'days  ' : 'day  '}` : ''}`.trim();
};
