import { CustomAsyncSelect, CustomTextField, CustomYearMonthPicker, CustomYearPicker } from "../components/mui/Input";

const Space = () => {
    return <div className={`w-full col-span-full my-1 `} />
}

const fieldComponents = {
    input: CustomTextField,
    select: CustomAsyncSelect,
    date: CustomYearPicker,
    year: CustomYearMonthPicker,
    space: Space
};

const renderFormField = (field = {}, index, isLoading) => {
    const { type = "input", label, value, onChange, name, options, size = 1, color = 'white', disabled = false, required = true, inputType = '', sx, ...props } = field;
    const FieldComponent = fieldComponents[type];
    return (
        <FieldComponent
            className={`bg-${color} col-span-${size}`}
            key={index}
            label={label}
            options={options}
            value={value}
            onChange={onChange}
            name={name}
            disabled={disabled}
            required={required}
            type={inputType}
            sx={sx}
            skeletonLoading={isLoading}
            {...props}
        />
    );
};

export default renderFormField;