import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { MuiOtpInput } from 'mui-one-time-password-input'

import { Email, ChangeCircle } from '@mui/icons-material'

import { loadingState, loggedInState } from '../../recoil/state'
import useFormOperations from '../../hooks/useFormOperations'
import usePostData from '../../hooks/usePostData'
import useSnackbar from '../../hooks/useSnackbar'

import { ConfirmButton, PurpleButton } from '../../components/mui/button/Button'
import { CustomTextField } from '../../components/mui/Input'
import BlackToolTip from '../../components/mui/BlackToolTip'

const LoginOtp = ({ emailSubmitted, setEmailSubmitted }) => {

    const navigate = useNavigate();
    const { handleSnackbarOpen } = useSnackbar();
    const setLoading = useSetRecoilState(loadingState);
    const setLoggedInState = useSetRecoilState(loggedInState);
    const { postData } = usePostData();

    const [otp, setOtp] = useState('');

    const validateChar = (value) => !isNaN(value);
    const handleOtpChange = (newValue) => setOtp(newValue);
    const toggleEmailSubmitted = () => setEmailSubmitted(!emailSubmitted);

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            const otpVerifyButton = document.getElementById('otpVerify');
            if (otpVerifyButton) otpVerifyButton.click();
        }
    };

    const onGenerateOtp = async () => {
        const OTPFormData = { email: formData?.email }
        setLoading(true);
        const { success, message } = await postData('/api/login/loginGenerateOTP', OTPFormData)
        setLoading(false);

        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen("Opps.. Some Error occurred while sending OTP to your Email ID!", 'error')

        handleSnackbarOpen('OTP sent to your Email ID!', 'success');
        setEmailSubmitted(true);
    }

    const onOTPVerify = async () => {
        const OTPFormData = { email: formData?.email, otp }
        setLoading(true);
        const { success, message, data } = await postData('/api/login/loginVerifyOTP', OTPFormData)
        setLoading(false);
        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen("Opps.. Some Error occurred!", 'error');

        const { loginType } = data;
        setLoggedInState(loginType);
        handleSnackbarOpen("OTP Verified successfully!", 'success');

        if (loginType === 'Admin Login') return navigate('/admin/profile?type=Personal');
        navigate('/login');
    }

    const { formData, handleSubmit, handleChange } = useFormOperations({}, onGenerateOtp);

    return (
        <form className='flex flex-col gap-6 w-full justify-center items-center' onSubmit={handleSubmit}>

            <CustomTextField
                name="email" label="Email Address" color="secondary" type="email" placeholder="abc@example.com"
                value={formData?.email ?? ''} onChange={handleChange} disabled={emailSubmitted} fullWidth
                startIcon={<Email className="text-regal-purple-600" />}
                endIcon={emailSubmitted &&
                    <BlackToolTip arrow message='Change email address?'>
                        <ChangeCircle className="text-light hover:text-regal-purple-600 cursor-pointer" onClick={toggleEmailSubmitted} />
                    </BlackToolTip>
                }
            />

            {emailSubmitted ? <div className='flex flex-col gap-[7px] w-full'>
                <MuiOtpInput value={otp} onChange={handleOtpChange} length={6} validateChar={validateChar} gap={1.5} sx={{ "& .MuiInputBase-input": { height: 18 } }} onKeyDown={handleKeyDown} />
                <p className="flex gap-x-2 text-sm">
                    <span className="font-poppins text-sm text-light">Didn't receive the code?</span>
                    <button type="submit" className="font-poppins text-sm text-regal-purple-500 hover:underline ">Resend it.</button>
                </p>
            </div> : <p className="h-[81px] grid place-items-center border-dashed border-2 w-full p-3">
                <span className="text-center text-s leading-6">
                    Sign in seamlessly with OTP. <br />
                    Verify your Email ID.
                </span>
            </p>}

            {emailSubmitted ?
                <PurpleButton id='otpVerify' disabled={otp.length < 6} size='large' fullWidth type="button" text="Verify OTP" onClick={onOTPVerify} sx={{ paddingX: 3, paddingY: 1.3 }} />
                : <ConfirmButton fullWidth size='large' type="submit" text="Generate OTP" sx={{ paddingX: 3, paddingY: 1.3 }} />}

        </form>
    )
}

export default LoginOtp;