import React from "react";
import { useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";

import { loadingState } from "../../../../recoil/state";
import usePopup from "../../../../hooks/usePopup";
import useSnackbar from "../../../../hooks/useSnackbar";
import useGetData from "../../../../hooks/useGetData";
import usePutData from "../../../../hooks/usePutData";

import CustomTable from "../../../../components/mui/Table";
import ProfessorIcon from '../../../../assets/icons/ProfessorIcon.svg'
import TransferIcon from "../../../../assets/icons/TransferIcon";
import RenderStats from "../../../../utils/RenderStats";

const FormerFaculty = () => {
    const { data: facultyData, isLoading: facultyDataLoading, updateData } = useGetData('/api/employee/hr/formers/getFormers')
    const { data: statsData, isLoading: statsLoading } = useGetData('/api/employee/hr/formers/stats/getDesignationsStats');

    const navigate = useNavigate();
    const { openPopup } = usePopup();
    const { handleSnackbarOpen } = useSnackbar();
    const { putData } = usePutData();
    const setLoading = useSetRecoilState(loadingState);

    const handleRow = (event, value) =>
        openPopup('View Details', 'Are you sure you want to view further details?', 'view', () => navigate("/admin/employee/formers/profile/" + value + '?type=Personal'));

    const handleReOnBoarding = (event, value) => openPopup('Transfer to Current Employee', 'Are you sure you want to transfer this former Employee to current Employee?', 'edit', () => onReOnBoarding(value));

    const onReOnBoarding = async (id) => {
        setLoading(true);
        const { success, message } = await putData(`/api/employee/hr/currents/updateCurrentTransfer/${id}`);
        setLoading(false);

        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen('Opps... some error occurred!', 'error');
        updateData(prevState => { return prevState.filter(user => user._id !== id) });
        handleSnackbarOpen(`Employee successfully Transferred!`, 'success');
    }

    const columns = [
        { id: 'srno', label: 'Sr.No', minWidth: 100, type: 'text' },
        { id: 'name', label: 'Faculty Name', minWidth: 180, type: 'text' },
        { id: 'emailId', label: 'Email ID', minWidth: 200, type: 'text' },
        { id: 'phNo', label: 'Contact No.', minWidth: 200, type: 'text' },
        { id: 'departments', label: 'Department', minWidth: 180, type: 'text' },
        { id: 'designation', label: 'Designation', minWidth: 180, type: 'text' },
        { id: 'joiningDate', label: 'Joining Date', minWidth: 140, type: 'date' },
        { id: 'leavingDate', label: 'Leaving Date', minWidth: 140, type: 'date' },
        {
            id: 'reonboard', label: 'Transfer to Current', type: 'iconButton',
            align: 'center', onClick: handleReOnBoarding,
            render: (row) => (
                <div className="flex items-center justify-center">
                    <TransferIcon className="h-6 w-6 fill-purple-700" />
                </div>
            )
        },
    ];

    const filterOptions = [
        { label: "N/A", value: "N/A" },
    ]

    return (
        <div className='flex flex-col gap-8 bg-white p-5 rounded-lg drop-shadow-md min-h-[calc(100svh-5rem)]'>
            <RenderStats icon={ProfessorIcon} data={statsData} statsLoading={statsLoading} />

            <CustomTable
                heading="Former Employees"
                text="Former Employees data"
                isLoading={facultyDataLoading}
                columns={columns} rows={facultyData}
                sortParameters={['name', 'designation', 'joiningDate', 'departments', 'leavingDate']}
                filterOptions={filterOptions}
                handleRowClick={handleRow}
                activeOperations
            />
        </div>
    )
}

export default FormerFaculty;