import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { IconButton, useMediaQuery } from '@mui/material'

import useGetData from '../../../../hooks/useGetData'

import SkeletonText from '../../../../components/mui/text/SkeltonText'
import TextBox from '../../../../components/mui/text/TextBox'

import DivisionIcon from '../../../../assets/icons/organization/divisionIcon.svg'
import DepartmentIcon from '../../../../assets/icons/organization/departmentIcon.svg'
import ProgramIcon from '../../../../assets/icons/organization/programIcon.svg'
import FacebookIcon from '../../../../assets/logo/facebook.svg'
import TwitterIcon from '../../../../assets/logo/twitter.svg'
import LinkedInIcon from '../../../../assets/logo/linkedin.svg'
import InstagramIcon from '../../../../assets/logo/instagram.svg'
import FilterComponent from './FilterComponent'

const OrganizationDashboard = () => {
    const { data: organizationData, isLoading } = useGetData('/api/organization/getOrganizationInfo')

    const xs = useMediaQuery('(max-width:500px)');

    const statsData = [
        { heading: 'Total Divisions', count: organizationData?.stats?.divisionsCount, icon: DivisionIcon },
        { heading: 'Total Departments', count: organizationData?.stats?.departmentsCount, icon: DepartmentIcon },
        { heading: 'Total Programs', count: organizationData?.stats?.programsCount, icon: ProgramIcon }
    ]

    const organizationInfo = [
        { label: 'Affiliated University', value: organizationData?.affiliatedUniversity },
        { label: 'Established Year', value: organizationData?.establishedYear },
        { label: 'Address', value: organizationData?.address, className: 'col-span-full' }
    ]

    const socialLinks = [
        { icon: FacebookIcon, to: organizationData?.socialMediaLinks?.facebook },
        { icon: TwitterIcon, to: organizationData?.socialMediaLinks?.twitter },
        { icon: LinkedInIcon, to: organizationData?.socialMediaLinks?.linkedIn },
        { icon: InstagramIcon, to: organizationData?.socialMediaLinks?.instagram },
    ]

    return (
        <div className="flex flex-col gap-5 bg-white p-3 xs:p-5 min-h-[calc(100svh-5rem)]">
            <div className='grid grid-cols-1 xs:grid-cols-20 border drop-shadow bg-white rounded-[4px]'>
                <div className='col-span-full flex flex-col xs:flex-row items-center p-5 gap-5 border-b-2'>
                    <Link to={organizationData?.officialWebsite} target="_blank" rel="noopener noreferrer">
                        <img draggable={false} className='h-20 p-2 lg:p-4 rounded-md border-[#5d45db] xs:border-[1px]' src={organizationData?.logoDefaultURL} alt={organizationData?.name} />
                    </Link>

                    <div className='flex flex-col items-center xs:items-start gap-1 w-full'>
                        <SkeletonText loading={isLoading} width={xs ? '60%' : '40%'} className='h3-p sm:h2-p' skeletonClassName='w-[60%]'>{organizationData?.name}</SkeletonText>

                        <SkeletonText width={xs ? '80%' : '60%'} className='lb-s text-purple' loading={isLoading} skeletonClassName='w-[80%]'>{organizationData?.tagline}</SkeletonText>
                    </div>
                </div>

                <div className='xs:col-span-15 grid grid-cols-1 xs:grid-cols-2 gap-4 p-5'>
                    {
                        organizationInfo?.map((field, index) =>
                            <TextBox className={field?.className} key={index} label={field?.label} value={field?.value} loading={isLoading} />
                        )
                    }
                </div>

                <div className='xs:col-span-5 flex flex-col gap-4 p-5 border-t-2 xs:border-l-2 xs:border-t-0'>
                    <h4 className='col-span-full h4-p h-fit'>Social Links</h4>
                    <div className='flex gap-2'>
                        {
                            socialLinks?.map((link, index) =>
                                <IconButton key={index} onClick={() => window.open(link?.to, '_blank')} className='w-fit'>
                                    <img className='h-7 w-fit' src={link?.icon} alt='Social Links' />
                                </IconButton>
                            )
                        }
                    </div>
                </div>
            </div>

            <FilterComponent organizationStats={statsData} isLoading={isLoading} />
        </div>
    )
}

export default OrganizationDashboard