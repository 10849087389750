import { useNavigate } from "react-router-dom"
import { KeyboardBackspace } from '@mui/icons-material';

import useGetData from "../../../../hooks/useGetData";

import AdmissionTile from "../../../../components/tiles/AdmissionTile";
import CustomTable from "../../../../components/mui/Table";
import { SquareIconButton } from "../../../../components/mui/button/SquareIconButton";
import ProfessorIcon from '../../../../assets/icons/ProfessorIcon.svg'

const FacultyGrid = ({ facultyInfo, statsLoading }) => {

    return (
        <div className={`grid gap-4 grid-cols-1 xs:grid-cols-2 lg:grid-cols-3`}>
            {(facultyInfo ? Object.keys(facultyInfo) : Array.from({ length: 6 })).map((faculty, index) => {
                return (
                    <AdmissionTile
                        key={faculty}
                        icon={ProfessorIcon}
                        color="#D6D0F7"
                        heading={faculty}
                        count={facultyInfo?.[faculty]}
                        isLoading={statsLoading}
                    />
                );
            })}
        </div>
    );
};

const IncompleteRecruits = () => {
    const { data: statsData, isLoading: statsLoading } = useGetData('/api/employee/hr/recruits/stats/getIncompleteRecruitDesignations');
    const { data: defaultData, isLoading } = useGetData('/api/employee/hr/recruits/getIncompleteRecruits');

    const navigate = useNavigate();

    const customOperations = () => {
        return <SquareIconButton title='Recruits' sx={{ backgroundColor: 'white', border: 1, borderColor: '#cbd5e1' }} icon={<KeyboardBackspace sx={{ color: "#512C9D", fontSize: 24 }} />} onClick={() => navigate('/admin/employee/recruits')} />
    }

    const columns = [
        { id: 'srno', label: 'Sr.No', minWidth: 100, type: 'text' },
        { id: 'name', label: 'Name', minWidth: 180, type: 'text' },
        { id: 'emailId', label: 'Email ID', minWidth: 200, type: 'text' },
        { id: 'phNo', label: 'Contact No.', minWidth: 140, type: 'text' },
        { id: 'departments', label: 'Departments', minWidth: 180, type: 'array' },
        { id: 'joiningDate', label: 'Joining Date', type: 'date' },
        { id: 'collegeName', label: 'College Name', type: 'text' }
    ];

    const filterOptions = [
        { label: "N/A", value: "N/A" },
    ]

    return (
        <div className='flex flex-col gap-8 bg-white p-5 min-h-[calc(100svh-5rem)]'>
            <FacultyGrid facultyInfo={statsData} statsLoading={statsLoading} />

            <CustomTable
                heading="All Pending Recruits"
                text="Pending Recruits data"
                isLoading={isLoading}
                columns={columns} rows={defaultData}
                sortParameters={['name', 'emailId', 'joiningDate']}
                filterOptions={filterOptions}
                activeOperations
                extraOperations={customOperations}
            />
        </div>
    )
}

export default IncompleteRecruits;