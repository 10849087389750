import { Tab, Tabs } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

const MiniNavbar = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const profileNavLinks = [
        { path: '/admin/profile?type=Personal', label: "Profile" },
    ]

    const studentNavLinks = [
        { path: '/admin/student/applicants', label: "Applicants" },
        { path: '/admin/student/enrolleds', label: "Enrolled" },
        { path: '/admin/student/alumnis', label: "Alumni" },
    ]

    const employeeNavLinks = [
        { path: '/admin/employee/recruits', label: "Recruits" },
        { path: '/admin/employee/currents', label: "Current" },
        { path: '/admin/employee/formers', label: "Former" }
    ]

    const organizationNavLinks = [
        { path: '/admin/organization/dashboard', label: "Dashboard" },
        { path: '/admin/organization/registration', label: "Registration" },
    ]

    const navLinkMap = {
        "/admin/profile": profileNavLinks,
        "/admin/employee": employeeNavLinks,
        "/admin/student": studentNavLinks,
        "/admin/organization": organizationNavLinks
    }

    const currentPath = location.pathname;
    const navLinks = navLinkMap[Object.keys(navLinkMap).find(key => currentPath.startsWith(key))];
    const index = navLinks?.findIndex((key) => currentPath?.includes(key.path));
    const handleTabClick = (path) => navigate(path);

    return (
        <div className='flex items-center px-3 sm:px-5 md:px-8 justify-center w-full gap-5'>
            <Tabs variant='scrollable' scrollButtons={false}
                className='flex items-center'
                value={index !== -1 ? index : 0} sx={{ "& .MuiTabs-indicator": { display: 'none' } }}>
                {navLinks?.map((tab, index) => (
                    <Tab key={index} label={tab.label} onClick={() => handleTabClick(tab.path)}
                        className='transition-all duration-100 mx-6'
                        disableTouchRipple
                        sx={{
                            textTransform: 'none',
                            '&.Mui-selected': { color: '#5D45DB', fontWeight: 600 },
                            fontFamily: 'Work Sans, sans-serif',
                            color: '#9CA3AF',
                            fontSize: 16, letterSpacing: 1.2
                        }}
                    />
                ))}
            </Tabs>
        </div>
    );
}

export default MiniNavbar;