import { Dialog } from "@mui/material";
import renderFormField from '../../../utils/renderFormField';
import { CloseButton, ConfirmButton } from "../../mui/button/Button";
import useFormOperations from "../../../hooks/useFormOperations";

const InputPopup = ({ icon, heading, subtext, fields, onConfirm, onClose }) => {

    const { formData, handleChange, handleSubmit } = useFormOperations({}, onConfirm);
    const iconPaths = {
        success: '/icons/tabs/Success.svg',
        download: '/icons/tabs/Download.svg',
        view: '/icons/tabs/SuccessPurple.svg',
        delete: '/icons/tabs/Delete.svg',
        edit: '/icons/tabs/Edit.svg',
        warning: '/icons/tabs/Failed.svg'
    };

    return (
        <Dialog open={true} onClose={onClose}>
            <div className="flex flex-col p-6 gap-8 w-full sm:w-fit bg-white rounded-lg">
                <div className="flex sm:w-[30rem] gap-5">
                    <img className="col-span-2 w-12 h-12" alt="" loading="eager" src={iconPaths?.[icon]} />
                    <div >
                        <h2 className="h4-p">{heading}</h2>
                        <p className="text-s">{subtext}</p>
                    </div>
                </div>

                <form onSubmit={handleSubmit} className="flex flex-col gap-5 w-full">
                    {fields.map((field, index) => renderFormField({ ...field, value: formData?.[field?.name], onChange: handleChange }, index))}
                    <div className="flex flex-col sm:flex-row items-center justify-between gap-5">
                        <CloseButton fullWidth size="large" text="Cancel" onClick={onClose} />
                        <ConfirmButton fullWidth size="large" text="Confirm" type='submit' />
                    </div>
                </form>
            </div>
        </Dialog>
    )
}

export default InputPopup;