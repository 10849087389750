import React from "react";
import { useNavigate } from "react-router-dom"
import { KeyboardBackspace } from '@mui/icons-material';

import useGetData from "../../../../hooks/useGetData";

import StudentIcon from '../../../../assets/icons/StudentIcon.svg'
import AdmissionTile from "../../../../components/tiles/AdmissionTile";
import CustomTable from "../../../../components/mui/Table";
import { SquareIconButton } from "../../../../components/mui/button/SquareIconButton";

const StudentGrid = ({ studentInfo, statsLoading }) => {
    return (
        <div className={`grid gap-4 grid-cols-1 xs:grid-cols-2 lg:grid-cols-3`}>
            {(studentInfo ? Object.keys(studentInfo) : Array.from({ length: 6 })).map((student, index) => {
                return (
                    <AdmissionTile
                        key={student}
                        icon={StudentIcon}
                        color="#D6D0F7"
                        heading={student}
                        count={studentInfo?.[student]}
                        isLoading={statsLoading}
                    />
                );
            })}
        </div>
    );
};

const IncompleteApplicants = () => {
    const navigate = useNavigate();
    const { data: statsData, isLoading: statsLoading } = useGetData('/api/employee/admission/applicants/stats/getIncompleteApplicantsProgramsCount');
    const { data: defaultData, isLoading } = useGetData('/api/employee/admission/applicants/getIncompleteApplicants');

    const customOperations = () => {
        return <SquareIconButton title='Applicants' sx={{ backgroundColor: 'white', border: 1, borderColor: '#cbd5e1' }} icon={<KeyboardBackspace sx={{ color: "#512C9D", fontSize: 24 }} />} onClick={() => navigate('/admin/student/applicants')} />
    }

    const columns = [
        { id: 'srno', label: 'Sr.No', minWidth: 100, type: 'text' },
        { id: 'name', label: 'Name', minWidth: 180, type: 'text' },
        { id: 'emailId', label: 'Email ID', minWidth: 200, type: 'text' },
        { id: 'phNo', label: 'Contact No.', minWidth: 140, type: 'text' },
        { id: 'programKey', label: 'Program Key', minWidth: 180, type: 'text' },
        { id: 'admissionYear', label: 'Admission Year', type: 'text' },
        { id: 'roundType', label: 'Round Type', type: 'text' },
        { id: 'roundId', label: 'Round ID', type: 'text' },
        { id: 'collegeName', label: 'College Name', type: 'text' }
    ];

    const filterOptions = [
        { label: "N/A", value: "N/A" },
    ]

    return (
        <div className='flex flex-col gap-8 bg-white p-5 min-h-[calc(100svh-5rem)]'>
            <StudentGrid studentInfo={statsData} statsLoading={statsLoading} />
            <CustomTable
                heading="All Pending Applicants"
                text="Pending Applicants data"
                isLoading={isLoading}
                columns={columns} rows={defaultData}
                sortParameters={['name', 'admissionYear', 'programKey']}
                filterOptions={filterOptions}
                activeOperations
                extraOperations={customOperations}
            />
        </div>
    )
}

export default IncompleteApplicants;