import React from 'react'
import AdmissionTile from '../components/tiles/AdmissionTile';

const RenderStats = ({ icon, data, statsLoading }) => {
  return (
    <div className={`grid gap-4 grid-cols-1 xs:grid-cols-2 lg:grid-cols-3`}>
            {(data ? Object.keys(data) : Array.from({ length: 6 })).map((faculty, index) => {
                return (
                    <AdmissionTile
                        key={index}
                        icon={icon}
                        color="#D6D0F7"
                        heading={faculty}
                        count={data?.[faculty]}
                        isLoading={statsLoading}
                    />
                );
            })}
        </div>
  )
}

export default RenderStats;