import React from 'react'
import AdmissionTile from '../../../../components/tiles/AdmissionTile';

const OrganizationStats = ({ statsData }) => {
    const { organizationStats, isLoading, studentStats, studentCountsLoading, employeeStats, employeeCountsLoading } = statsData;

    return (
        <>
            <div className={`grid gap-4 grid-cols-1 xs:grid-cols-2 lg:grid-cols-3`}>
                {organizationStats?.map((stats, index) => {
                    return (
                        <AdmissionTile
                            key={index}
                            icon={stats?.icon}
                            color="#D6D0F7"
                            heading={stats?.heading}
                            count={stats?.count}
                            isLoading={isLoading}
                        />
                    );
                })}
            </div>

            <div className='grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-3 gap-4'>
                {studentStats?.map((stats, index) => {
                    return (
                        <AdmissionTile
                            key={index}
                            icon={stats?.icon}
                            color="#D6D0F7"
                            heading={stats?.heading}
                            count={stats?.count}
                            isLoading={studentCountsLoading}
                        />
                    );
                })}

                {employeeStats?.map((stats, index) => {
                    return (
                        <AdmissionTile
                            key={index}
                            icon={stats?.icon}
                            color="#D6D0F7"
                            heading={stats?.heading}
                            count={stats?.count}
                            isLoading={employeeCountsLoading}
                        />
                    );
                })}
            </div>
        </>
    )
}

export default OrganizationStats