import React, { useState } from "react";
import { useSetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom"
import { CloudDownload, PendingOutlined, CancelOutlined, VerifiedOutlined, ScreenRotationAlt, PersonOff, PersonAdd, MoreHoriz } from '@mui/icons-material';

import { loadingState } from "../../../../recoil/state";
import { dateFormat } from "../../../../utils/dateFormat";

import usePopup from '../../../../hooks/usePopup';
import useGetData from "../../../../hooks/useGetData";
import usePutData from "../../../../hooks/usePutData";
import usePostData from "../../../../hooks/usePostData";
import useSnackbar from "../../../../hooks/useSnackbar";
import useFetchData from "../../../../hooks/useFetchData";

import PromoteIcon from '../../../../assets/icons/PromoteIcon'
import PdfIcon from "../../../../assets/icons/documents/PdfIcon";
import DocumentVerified from '../../../../assets/icons/admission/DocumentVerified.svg';
import DocumentPending from '../../../../assets/icons/admission/DocumentPending.svg';
import DocumentRejected from '../../../../assets/icons/admission/DocumentRejected.svg';
import DocumentDeclined from '../../../../assets/icons/admission/DocumentDeclined.svg';

import { SquareIconButton } from "../../../../components/mui/button/SquareIconButton";
import BlackToolTip from "../../../../components/mui/BlackToolTip";
import AdmissionTile from "../../../../components/tiles/AdmissionTile";
import CustomTable from "../../../../components/mui/Table";
import PromoteApplicantDialog from "../../../../components/popups/localPopup/verification/PromoteApplicantDialog";
import InputPopup from "../../../../components/popups/globalPopup/InputPopup";

const StudentApplicants = () => {
    const navigate = useNavigate();
    const { openPopup } = usePopup();
    const { handleSnackbarOpen } = useSnackbar();
    const setLoading = useSetRecoilState(loadingState);
    const { data: statsData, isLoading: statsLoading } = useGetData('/api/employee/admission/applicants/stats/getStats');
    const { data: applicantData, isLoading, updateData } = useGetData('/api/employee/admission/applicants/getApplicants');
    const { putData } = usePutData();
    const { postData } = usePostData();
    const { error, fetchData } = useFetchData();

    const [promotePopup, setPromotePopup] = useState({ isOpen: false, _id: '' });
    const [addApplicantPopup, setAddApplicantPopup] = useState(false);

    const customOperations = () => {
        return <div className="grid grid-cols-2 xs:grid-cols-4 gap-4">
            <SquareIconButton title='Inprogress Applicants' sx={{ backgroundColor: 'white', border: 1, borderColor: '#cbd5e1' }} icon={<MoreHoriz sx={{ color: "orange", fontSize: 24 }} />} onClick={() => navigate('/admin/student/applicants/inProgress')} />

            <SquareIconButton title='Add Applicant' sx={{ backgroundColor: 'white', border: 1, borderColor: '#cbd5e1' }} icon={<PersonAdd sx={{ color: "#512C9D", fontSize: 24 }} />} onClick={() => setAddApplicantPopup(true)} />

            <SquareIconButton title='Blocked Applicants' sx={{ backgroundColor: 'white', border: 1, borderColor: '#cbd5e1' }} icon={<PersonOff sx={{ color: "red", fontSize: 24 }} />} onClick={() => navigate('/admin/student/applicants/blocked')} />

            <SquareIconButton title='Download Excel' sx={{ backgroundColor: 'white', border: 1, borderColor: '#cbd5e1' }} icon={<CloudDownload sx={{ color: "green", fontSize: 24 }} />} />
        </div>
    }

    const handleAddApplicant = async (formData) => {
        setAddApplicantPopup(false);
        setLoading(true);
        const { success, message, data } = await postData('/api/employee/admission/applicants/addApplicant', formData);
        setLoading(false);

        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen('Opps... some error occurred!', 'error');
        navigate('/admin/student/applicants/addApplicant/' + data.id + '?step=Personal+Details');
    }

    const handleRow = (event, value) =>
        openPopup('View Details', 'Are you sure you want to view further details?', 'view', () => navigate("/admin/student/applicants/profile/" + value + '?type=Personal'));

    const handleOnlineVerification = (event, value) =>
        openPopup('Review Details', 'Are you sure you want to view further details of Online Verification?', 'view', () => navigate("/admin/student/applicants/onlineVerify/" + value));

    const handleOfflineVerification = (event, value) =>
        openPopup('Verify Documents Offline', 'Are you sure you want to view further details of Offline Verification?', 'view', () => navigate("/admin/student/applicants/offlineVerify/" + value));

    const handlePromote = (event, id) => {
        const user = applicantData?.find(user => user._id === id) || {};
        if (user?.promoted) return;
        if (!(user?.onlineVerificationStatus === 'Verified' || user?.offlineVerificationStatus === 'Verified'))
            return handleSnackbarOpen('Verification required: User must be verified either online or offline.', 'warning');
        setPromotePopup({ isOpen: true, _id: id });
    };

    const onPromote = async (data) => {
        setPromotePopup({ isOpen: false, _id: '' });
        const user = applicantData?.find(user => user?._id === data?._id) || {};

        setLoading(true);
        const { success, message } = await putData(`/api/employee/admission/applicants/updateApplicantPromotion/${data?._id}`, data);
        setLoading(false);

        if (message) return handleSnackbarOpen(message, 'warning');
        if (!success) return handleSnackbarOpen('Opps... some error occurred!', 'error');
        if (user?.onlineVerificationStatus === 'Verified' && user?.offlineVerificationStatus === 'Verified') updateData(prevState => { return prevState.filter(user => user._id !== data?._id) });

        updateData(prevState => prevState.map(user => user._id === data._id ? { ...user, promoted: true, promotedTimeStamp: new Date() } : user));
        handleSnackbarOpen(`Applicant successfully promoted!`, 'success');
    }

    const handleRetention = async (event, id, row) => {
        if (row?.studentRetention?.length === 0) return handleSnackbarOpen('Student Retention is Pending', 'error');
        const signedURL = await fetchData(`/api/employee/admission/applicants/getViewURL/${id}?key=${row?.studentRetention}`);
        if (error) return handleSnackbarOpen('Opps.. Some Error occurred', 'error');
        window.open(signedURL, '_blank');
    }

    const tileData = [
        { icon: DocumentVerified, color: "#D1FADF", heading: "Online Verified", count: statsData?.online?.Verified },
        { icon: DocumentPending, color: "#FEF0C7", heading: "Online Pending", count: statsData?.online?.Pending },
        { icon: DocumentRejected, color: "#D6D0F7", heading: "Online Rejected", count: statsData?.online?.Rejected },
        { icon: DocumentDeclined, color: "#ffdfdf", heading: "Blocked Applicant", count: statsData?.online?.Declined },
        { icon: DocumentVerified, color: "#D1FADF", heading: "Offline Verified", count: statsData?.offline?.Verified },
        { icon: DocumentPending, color: "#FEF0C7", heading: "Offline Pending", count: statsData?.offline?.Pending }
    ]

    const columns = [
        { id: 'srno', label: 'Sr.No', minWidth: 100, type: 'text' },
        { id: 'name', label: 'Name', minWidth: 180, type: 'text' },
        { id: 'emailId', label: 'Email ID', minWidth: 200, type: 'text' },
        { id: 'phNo', label: 'Contact No.', minWidth: 140, type: 'text' },
        { id: 'programKey', label: 'Program Key', minWidth: 180, type: 'text' },
        { id: 'admissionYear', label: 'Admission Year', minWidth: 140, type: 'text' },
        {
            id: 'onlineVerificationStatus', label: 'Online Verification', type: 'iconButton',
            align: 'center', onClick: handleOnlineVerification,
            render: (row) => (
                <div className="flex flex-col items-center justify-center gap-2">
                    {(row?.reSubmitted && row?.onlineVerificationStatus === 'Pending') ?
                        <BlackToolTip title="Re-submitted">
                            <ScreenRotationAlt className="text-purple" />
                        </BlackToolTip>
                        : row?.onlineVerificationStatus === 'Pending' ?
                            <PendingOutlined className="text-amber-500" />
                            : <>
                                {row?.onlineVerificationStatus === 'Verified' ?
                                    <VerifiedOutlined className="text-green" />
                                    : <CancelOutlined className="text-signal" />
                                }
                                <div className="text-xs">{dateFormat(row.onlineVerificationTimeStamp)}</div>
                                <div className="text-s text-xs text-signal">{row.onlineVerificationAuthority}</div>
                            </>
                    }
                </div>
            )
        },
        {
            id: 'offlineVerificationStatus', label: 'Offline Verification', type: 'iconButton',
            align: 'center', onClick: handleOfflineVerification,
            render: (row) => {
                return (
                    <div className="flex flex-col items-center justify-center gap-2">
                        {row?.offlineVerificationStatus === 'Verified' ? <>
                            <VerifiedOutlined className="text-green" />
                            <div className="text-xs">{dateFormat(row.offlineVerificationTimeStamp)}</div>
                            <div className="text-s text-xs text-signal">{row.offlineVerificationAuthority}</div>
                        </> : <PendingOutlined className="text-amber-500" />
                        }
                    </div>
                )
            }
        },
        {
            id: 'studentRetention', label: 'Retention Document', type: 'iconButton',
            align: 'center', onClick: handleRetention,
            render: (row) => (
                <div className="flex flex-col items-center justify-center gap-2">
                    {row?.studentRetention === '' ?
                        <PendingOutlined className="text-amber-500" />
                        : (row?.studentRetention ? <BlackToolTip title="View Document">
                            <p>
                                <PdfIcon className="flex flex-shrink-0 fill-red-500 h-8 w-8" />
                            </p>
                        </BlackToolTip> : <span> N/A </span>)
                    }
                </div>
            )
        },
        {
            id: 'promoted', label: 'Transfer to Enrolled', type: 'iconButton',
            align: 'center', onClick: handlePromote,
            render: (row) => (
                <div className="flex flex-col items-center justify-center gap-2">
                    {row?.promoted ? <>
                        <VerifiedOutlined className="text-green" />
                        <div className="text-xs">{dateFormat(row?.promotedTimeStamp)}</div>
                        <div className="text-s text-xs text-signal">{row?.promotedAuthority}</div>
                    </> : <PromoteIcon className="h-6 w-6 fill-purple-700" />}
                </div>
            )
        }
    ];

    const filterOptions = [
        { label: "N/A", value: "N/A" },
    ]

    const addFields = [
        {
            type: 'input',
            inputType: 'email',
            label: 'Email ID',
            name: 'emailId',
            placeholder: 'abc@example.com'
        }
    ]

    return (
        <div className='flex flex-col gap-8 bg-white p-5 min-h-[calc(100svh-5rem)]'>
            <div className="grid sm:grid-cols-2 xl:grid-cols-4 gap-4">
                {tileData.map((tile, index) =>
                    <AdmissionTile key={index} icon={tile.icon} color={tile.color} heading={tile.heading} count={tile.count} isLoading={statsLoading} />
                )}
            </div>

            <CustomTable
                heading="All Applicants"
                text="Applicants data"
                isLoading={isLoading}
                columns={columns} rows={applicantData}
                sortParameters={['name', 'programKey', 'admissionYear']}
                filterOptions={filterOptions}
                handleRowClick={handleRow}
                activeOperations
                extraOperations={customOperations}
            />

            {promotePopup?.isOpen && <PromoteApplicantDialog id={promotePopup._id} onClose={() => setPromotePopup({ isOpen: false, _id: '' })} onConfirm={onPromote} />}

            {addApplicantPopup && <InputPopup fields={addFields} icon='edit' heading='Add Applicant' subtext='Enter the new applicant Email ID' onClose={() => setAddApplicantPopup(false)} onConfirm={handleAddApplicant} />}
        </div>
    )
}

export default StudentApplicants;