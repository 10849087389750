import { Checkbox } from "@mui/material";

import useSnackbar from "../../../../hooks/useSnackbar";
import usePutData from "../../../../hooks/usePutData";
import useGetData from "../../../../hooks/useGetData";
import usePopup from '../../../../hooks/usePopup';

import { disableTextField } from "../../../../constants/mui";
import { bloodGroups, disabilities, genders, maritalStatus, indianReligions, domains, motherTongues } from "../../../../constants/personal";
import { nationalities } from "../../../../constants/geoConstants";
import renderFormField from "../../../../utils/renderFormField";
import { generateGeoOptions } from "../../../../utils/geoLocationOptions";
import { deleteKeys } from "../../../../utils/formFunctions";

import BottomTile from "./BottomTile";
import AddComponent from "../../../../components/mui/AddComponent";
import { CustomAsyncSelect, CustomMultiCheckboxSelect, CustomTextField } from "../../../../components/mui/Input";

const PersonalDetails = ({ formData, verifiedValues, steps, formOperations, id, isLoading }) => {
    const { handleNestedChange, handleNestedSelectImmediateChange, handleSubmit, updateData, clearForm } = formOperations;

    const { data: departmentData, isLoading: departmentDataLoading } = useGetData(id ? `/api/employee/hr/recruits/getDepartments/${id}` : '/api/admission/employee/getDepartments');
    const { data: designationData, isLoading: designationDataLoading } = useGetData(id ? '/api/employee/hr/recruits/getDesignations' : '/api/admission/employee/getDesignations');
    const { data: categoriesData, isLoading: categoriesDataLoading } = useGetData(id ? `/api/employee/hr/recruits/getCategories` : '/api/admission/employee/getCategories');
    const { putData: updateCategory, isLoading: updateCategoryLoading } = usePutData();

    const { openPopup } = usePopup();
    const { handleSnackbarOpen } = useSnackbar();

    const departmentOptions = departmentData?.map(department => ({ label: department?.name, value: department?._id })) || [];
    const designationOptions = designationData?.map(designation => ({ label: designation, value: designation })) || [];
    const categoryOptions = categoriesData?.map(category => ({ value: category, label: category })) || [];

    const { countryOptions, stateOptions: permanentStateOptions, cityOptions: permanentCityOptions } = generateGeoOptions(formData?.personalInfo?.permanentAddress?.country, formData?.personalInfo?.permanentAddress?.state);
    const { stateOptions: residentialStateOptions, cityOptions: residentialCityOptions } = generateGeoOptions(formData?.personalInfo?.residentialAddress?.country, formData?.personalInfo?.residentialAddress?.state);

    const genderOptions = genders.map(gender => ({ value: gender, label: gender }));
    const bloodOptions = bloodGroups.map(blood => ({ value: blood, label: blood }));
    const religionOptions = indianReligions.map(religion => ({ value: religion, label: religion }));
    const nationalityOptions = nationalities.map(nationality => ({ value: nationality, label: nationality }));
    const domainOptions = domains.map(domain => ({ value: domain, label: domain }));
    const motherTongueOptions = motherTongues.map(motherTongue => ({ value: motherTongue, label: motherTongue }));
    const disabilityOptions = disabilities.map(disability => ({ value: disability, label: disability }))

    const handleDisable = (name) => {
        if (!verifiedValues) return false;
        const value = name?.split('.')?.reduce((acc, key) => acc?.[key], verifiedValues);
        return value?.status !== 'Rejected';
    };

    const admissionFields = [
        { type: 'date', label: "Admission Date", value: formData?.joiningDate, name: 'formData.joiningDate' },
        { type: 'select', label: "Designation", value: formData?.designation, name: "formData.designation", options: designationOptions, loading: designationDataLoading }
    ]

    const personalDetailsFields = [
        { type: 'input', label: "Middle Name", value: formData?.middleName, name: 'formData.middleName', disabled: handleDisable('middleName'), required: false },
        { type: 'input', label: "Last Name", value: formData?.lastName, name: 'formData.lastName', disabled: handleDisable('lastName') },
        { type: 'select', label: "Gender", value: formData?.personalInfo?.gender, name: "formData.personalInfo.gender", options: genderOptions, disabled: handleDisable('personalInfo.gender') },
        { type: 'date', label: "Date of Birth", value: formData?.personalInfo?.dob, name: "formData.personalInfo.dob", disabled: handleDisable('personalInfo.dob') },
        { type: 'select', label: "Blood Group", value: formData?.personalInfo?.bloodGroup, name: "formData.personalInfo.bloodGroup", options: bloodOptions, disabled: handleDisable('personalInfo.bloodGroup') },
        { type: 'input', label: "Phone Number", value: formData?.phNo, name: "formData.phNo", customType: "number", disabled: handleDisable('phNo'), InputProps: { inputProps: { maxLength: 10 } } },
        { type: 'input', label: "Alternate Phone Number", value: formData?.alternatePhNo, name: "formData.alternatePhNo", customType: "number", disabled: !!verifiedValues, InputProps: { inputProps: { maxLength: 10 } }, required: false },
        { type: 'input', label: "Alternate Email ID", value: formData?.alternateEmailId, name: "formData.alternateEmailId", inputType: "email", disabled: !!verifiedValues, required: false, placeholder: 'Enter your personal email ID' },
        { type: 'space' },
        { type: 'input', size: 'full', label: "Residential Address", value: formData?.personalInfo?.residentialAddress?.address, name: "formData.personalInfo.residentialAddress.address", disabled: handleDisable('personalInfo.residentialAddress.address'), skeletonClassName: 'col-span-full' },
        { type: 'select', label: "Country", value: formData?.personalInfo?.residentialAddress?.country, name: "formData.personalInfo.residentialAddress.country", options: countryOptions, disabled: handleDisable('personalInfo.residentialAddress.country') },
        { type: 'select', label: "State", value: formData?.personalInfo?.residentialAddress?.state, name: "formData.personalInfo.residentialAddress.state", options: residentialStateOptions, noOptionsMessage: 'Select country first', disabled: handleDisable('personalInfo.residentialAddress.state') },
        { type: 'select', label: "City", value: formData?.personalInfo?.residentialAddress?.city, name: "formData.personalInfo.residentialAddress.city", options: residentialCityOptions, noOptionsMessage: 'Select state first', disabled: handleDisable('personalInfo.residentialAddress.city') },
        { type: 'input', label: "Town", value: formData?.personalInfo?.residentialAddress?.town, name: "formData.personalInfo.residentialAddress.town", disabled: handleDisable('personalInfo.residentialAddress.town') },
        { type: 'input', label: "Pincode", value: formData?.personalInfo?.residentialAddress?.pincode, name: "formData.personalInfo.residentialAddress.pincode", customType: 'number', InputProps: { inputProps: { maxLength: 6 } }, disabled: handleDisable('personalInfo.residentialAddress.pincode') },
        { type: 'space' },
    ];

    const permanentAddressDetailsFields = [
        { type: 'input', size: 'full', label: "Permanent Address", value: formData?.personalInfo?.permanentAddress?.address, name: "formData.personalInfo.permanentAddress.address", disabled: handleDisable('personalInfo.permanentAddress.address'), skeletonClassName: 'col-span-full' },
        { type: 'select', label: "Country", value: formData?.personalInfo?.permanentAddress?.country, name: "formData.personalInfo.permanentAddress.country", options: countryOptions, disabled: handleDisable('personalInfo.permanentAddress.country') },
        { type: 'select', label: "State", value: formData?.personalInfo?.permanentAddress?.state, name: "formData.personalInfo.permanentAddress.state", options: permanentStateOptions, noOptionsMessage: 'Select country first', disabled: handleDisable('personalInfo.permanentAddress.state') },
        { type: 'select', label: "City", value: formData?.personalInfo?.permanentAddress?.city, name: "formData.personalInfo.permanentAddress.city", options: permanentCityOptions, noOptionsMessage: 'Select state first', disabled: handleDisable('personalInfo.permanentAddress.city') },
        { type: 'input', label: "Town", value: formData?.personalInfo?.permanentAddress?.town, name: "formData.personalInfo.permanentAddress.town", disabled: handleDisable('personalInfo.permanentAddress.town') },
        { type: 'input', label: "Pincode", value: formData?.personalInfo?.permanentAddress?.pincode, name: "formData.personalInfo.permanentAddress.pincode", customType: 'number', InputProps: { inputProps: { maxLength: 6 } }, disabled: handleDisable('personalInfo.permanentAddress.pincode') },
    ];

    const identityInfoFields = [
        { type: 'select', label: "Nationality", value: formData?.identityInfo?.nationality, name: "formData.identityInfo.nationality", options: nationalityOptions },
        { type: 'select', label: "Religion", value: formData?.identityInfo?.religion, name: "formData.identityInfo.religion", options: religionOptions },
        { type: 'input', label: "Caste", value: formData?.identityInfo?.caste, name: "formData.identityInfo.caste" },
        { type: 'input', label: "Sub Caste", value: formData?.identityInfo?.subCaste, name: "formData.identityInfo.subCaste", required: false },
    ];

    const financialAndLegalInfoFields = [
        { type: 'input', label: "Bank Name", value: formData?.financialAndLegalInfo?.bankName, name: "formData.financialAndLegalInfo.bankName" },
        { type: 'input', label: "Account Number", value: formData?.financialAndLegalInfo?.accountNo, name: "formData.financialAndLegalInfo.accountNo", customType: "number" },
        { type: 'input', label: "IFSC Code", value: formData?.financialAndLegalInfo?.ifsc, name: "formData.financialAndLegalInfo.ifsc" },
        { type: 'input', label: "Aadhar Card Number", value: formData?.financialAndLegalInfo?.aadharCardNo, name: "formData.financialAndLegalInfo.aadharCardNo", customType: "number", InputProps: { inputProps: { maxLength: 12 } } },
        { type: 'input', label: "PAN Card Number", value: formData?.financialAndLegalInfo?.panCardNo, name: "formData.financialAndLegalInfo.panCardNo", InputProps: { inputProps: { maxLength: 10 } }, required: false },
    ];

    const additionalInfoFields = [
        { type: 'select', label: "Mother Tongue", value: formData?.additionalInfo?.motherTongue, name: "formData.additionalInfo.motherTongue", options: motherTongueOptions },
        { type: 'select', label: "Disability", value: formData?.additionalInfo?.disability, name: "formData.additionalInfo.disability", options: disabilityOptions }
    ];

    const familyFields = [
        { type: 'input', label: "Middle Name", name: 'middleName', required: false },
        { type: 'input', label: "Last Name", name: 'lastName' },
        { type: 'input', label: "Phone Number", name: 'phNo', customType: 'number', InputProps: { inputProps: { maxLength: 10 } } },
        { type: 'input', label: "Email ID", inputType: 'email', name: 'emailId', required: false },
        { type: 'input', label: "Income", name: "income", customType: "number", placeholder: 'Enter your annual Income' },
        { type: 'input', label: "Education", name: "education" },
        { type: 'input', label: "Profession", name: "profession" }
    ]

    const EmergencyPersonFields = [
        { type: 'input', label: "Middle Name", name: 'formData.familyInfo.emergencyPersonDetail.middleName', value: formData?.familyInfo?.emergencyPersonDetail?.middleName, required: false },
        { type: 'input', label: "Last Name", name: 'formData.familyInfo.emergencyPersonDetail.lastName', value: formData?.familyInfo?.emergencyPersonDetail?.lastName },
        { type: 'input', label: "Phone Number", name: 'formData.familyInfo.emergencyPersonDetail.phNo', value: formData?.familyInfo?.emergencyPersonDetail?.phNo, customType: 'number', InputProps: { inputProps: { maxLength: 10 } } },
        { type: 'input', label: "Relation", name: 'formData.familyInfo.emergencyPersonDetail.relation', value: formData?.familyInfo?.emergencyPersonDetail?.relation }
    ]

    const childrenFields = [
        { type: 'input', label: "Name", name: "name" },
        { type: 'date', label: "Date of Birth", name: "dob" },
        { type: 'input', label: "Education", name: "education" }
    ];

    const onConfirmCategory = async (event) => {
        const { message, success } = await updateCategory(id ? `/api/employee/hr/recruits/updateCategory/${id}?category=${event.target.value}` : `/api/admission/employee/updateCategory?category=${event.target.value}`);
        if (message) return handleSnackbarOpen(message, 'warning')
        if (!success) return handleSnackbarOpen("Opps.. Some Error occurred!", 'error');
        handleNestedSelectImmediateChange(event);
        handleSnackbarOpen("Your Category Successfully Updated", 'success');
    }

    const handleCategoryChange = (event) => {
        openPopup('Select Category', 'Do you want to change Category? Your uploaded category documents may get deleted', 'edit',
            () => onConfirmCategory(event),
            () => handleNestedSelectImmediateChange({ target: { name: 'formData.identityInfo.category', value: formData?.identityInfo?.category } }))
    };

    const handleChildrenCheckbox = () => {
        if (formData?.familyInfo?.childrenDetail?.length > 0) {
            let updatedFamilyData = { ...formData.familyInfo };
            delete updatedFamilyData.children;
            handleNestedChange({ target: { name: 'formData.familyInfo', value: updatedFamilyData } });
        } else {
            handleNestedChange({ target: { name: 'formData.familyInfo.children', value: [{}] } });
        }
    };

    const handleMotherDeceased = () => handleNestedChange({ target: { name: 'formData.familyInfo.motherDetail', value: { isDeceased: !formData?.familyInfo?.motherDetail?.isDeceased } } })
    const handleFatherDeceased = () => handleNestedChange({ target: { name: 'formData.familyInfo.fatherDetail', value: { isDeceased: !formData?.familyInfo?.fatherDetail?.isDeceased } } })

    const handleAddressCheckBox = () => {
        if (addressCheckBox()) {
            const updatedFormData = { ...formData }
            deleteKeys(updatedFormData?.personalInfo, ['permanentAddress'])
            updateData((formData) => ({ ...updatedFormData }));
        } else {
            permanentAddressDetailsFields?.forEach(field => {
                handleNestedChange({ target: { name: field?.name, value: formData?.personalInfo?.residentialAddress?.[field?.name?.split('.').pop()] } })
            });
        }
    };

    const addressCheckBox = () => {
        const residentialAddress = formData?.personalInfo?.residentialAddress;
        const permanentAddress = formData?.personalInfo?.permanentAddress;

        if (!residentialAddress || !permanentAddress) return false;

        return Object.keys(residentialAddress).every(k => {
            const resAddress = residentialAddress[k];
            const permAddress = permanentAddress[k];
            return !!resAddress && !!permAddress && resAddress === permAddress;
        });
    };

    const addressCheckBoxDisabled = () => {
        return Object.keys(formData?.personalInfo?.residentialAddress ?? {}).every(k => formData?.personalInfo?.residentialAddress[k] === '');
    }

    const handleMaritalStatus = (e) => {
        if (e.target.value !== 'Married') {
            delete formData?.familyInfo?.partnerDetail;
        }; handleNestedChange(e);
    }

    const handleCheckboxDisabled = (fields = {}) => verifiedValues ? !(Object.keys(fields).some((field) => fields[field]?.status === 'Rejected')) : false;

    return (
        <form className="flex flex-col" onSubmit={handleSubmit}>
            <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14 px-5 lg:px-14 sm:px-8 py-10 w-full bg-tertiary border-2">
                <h5 className="h4-p col-span-full">General Info</h5>
                <div className="col-span-full grid sm:grid-cols-2 gap-5 lg:gap-x-14">
                    <CustomTextField
                        className="bg-white"
                        label="College"
                        sx={disableTextField}
                        value={formData?.collegeName}
                        skeletonLoading={isLoading}
                    />

                    <CustomTextField
                        className="bg-white"
                        label="Email ID"
                        value={formData?.emailId}
                        sx={disableTextField}
                        name="formData.emailId"
                        disabled={handleDisable('emailId')}
                        skeletonLoading={isLoading}
                    />
                </div>

                <CustomMultiCheckboxSelect
                    className='col-span-full bg-white'
                    loading={departmentDataLoading}
                    label='Department'
                    value={formData?.departmentIds ?? []}
                    name='formData.departmentIds'
                    onChange={handleNestedChange}
                    options={departmentOptions}
                    color='secondary'
                    required={false}
                    disabled={handleDisable('departmentIds')}
                    skeletonLoading={isLoading}
                    skeletonClassName='col-span-full'
                />

                {admissionFields.map((field, index) => renderFormField({ ...field, disabled: handleDisable(field?.name?.split('.').pop()), onChange: handleNestedChange }, index, isLoading))}

                <CustomAsyncSelect
                    className="bg-white"
                    label="Category"
                    options={categoryOptions}
                    value={formData?.identityInfo?.category}
                    onChange={handleCategoryChange}
                    name="formData.identityInfo.category"
                    required={false}
                    disabled={handleDisable('identityInfo.category')}
                    loading={updateCategoryLoading || categoriesDataLoading}
                    skeletonLoading={isLoading}
                />

            </div>

            <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14 px-5 lg:px-14 sm:px-8 py-10 w-full border-2">
                <h5 className="h4-p col-span-full">Personal Info</h5>
                <div className="flex w-full">
                    <CustomAsyncSelect
                        className='bg-white w-24' label="Title"
                        options={[
                            { value: 'Dr', label: 'Dr' },
                            { value: 'Mr', label: 'Mr' },
                            { value: 'Mrs', label: 'Mrs' },
                            { value: 'Ms', label: 'Ms' },
                        ]} onChange={handleNestedChange} disabled={!!verifiedValues}
                        name='formData.title' value={formData?.title}
                        style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                        skeletonLoading={isLoading}
                        skeletonClassName='rounded-r-none'
                    />

                    <CustomTextField
                        label="First Name" name='formData.firstName' value={formData?.firstName} onChange={handleNestedChange} disabled={handleDisable('firstName')} fullWidth className='bg-white' InputProps={{ style: { borderRadius: "0px 4px 4px 0px" } }} skeletonLoading={isLoading}
                    />
                </div>

                {personalDetailsFields.map((field, index) => renderFormField({ ...field, onChange: handleNestedChange }, index, isLoading))}

                {isLoading ?
                    <div className="col-span-full animate-pulse rounded-md bg-muiLight h-11" />
                    : <div className="col-span-full flex items-center">
                        <Checkbox id="address-checkbox" checked={addressCheckBox()} color="secondary" disabled={handleCheckboxDisabled(verifiedValues?.personalInfo?.permanentAddress) || addressCheckBoxDisabled()} onChange={handleAddressCheckBox} />
                        <label htmlFor="address-checkbox" className="text-s cursor-pointer">Set Permanent Address same as Residential Address</label>
                    </div>}

                {!addressCheckBox() && permanentAddressDetailsFields.map((field, index) => renderFormField({ ...field, onChange: handleNestedChange }, index, isLoading))}
            </div>

            <div className="flex flex-col gap-10 px-5 lg:px-14 sm:px-8 py-10 w-full bg-tertiary border-2">
                <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14">
                    <h5 className="h4-p col-span-full">Identity Info</h5>
                    {identityInfoFields.map((field, index) => (renderFormField({ ...field, disabled: handleDisable('identityInfo.' + field?.name?.split('.').pop()), onChange: handleNestedChange }, index, isLoading)))}
                </div>

                <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14">
                    <h5 className="h4-p col-span-full">Financial & Legal Info</h5>
                    {financialAndLegalInfoFields.map((field, index) => (renderFormField({ ...field, disabled: handleDisable('financialAndLegalInfo.' + field?.name?.split('.').pop()), onChange: handleNestedChange }, index, isLoading)))}
                </div>

                <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14">
                    <h5 className="h4-p col-span-full">Additional Info</h5>
                    {additionalInfoFields.map((field, index) => (renderFormField({ ...field, disabled: handleDisable('additionalInfo.' + field?.name?.split('.').pop()), onChange: handleNestedChange }, index, isLoading)))}
                    <CustomAsyncSelect
                        className='bg-white' label="Marital Status"
                        options={maritalStatus.map(option => ({ value: option, label: option }))} onChange={handleMaritalStatus}
                        disabled={handleDisable('additionalInfo.maritalStatus')}
                        name='formData.additionalInfo.maritalStatus'
                        value={formData?.additionalInfo?.maritalStatus}
                        skeletonLoading={isLoading}
                    />
                    <CustomMultiCheckboxSelect
                        className='col-span-full bg-white'
                        color='secondary'
                        label='Domain'
                        value={formData?.additionalInfo?.domain ?? []}
                        name='formData.additionalInfo.domain'
                        onChange={handleNestedChange}
                        options={domainOptions}
                        disabled={!!verifiedValues}
                        skeletonLoading={isLoading}
                        skeletonClassName='col-span-full'
                    />
                </div>
            </div>

            <div className="flex flex-col gap-5 px-5 lg:px-14 sm:px-8 py-10 w-full border-2">
                {formData?.additionalInfo?.maritalStatus === 'Married' &&
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14">
                        <h5 className="h4-p col-span-full">Partner Details</h5>
                        <div className="flex w-full">
                            <CustomAsyncSelect
                                className='bg-white w-24' label="Title"
                                options={[
                                    { value: 'Dr', label: 'Dr' },
                                    { value: 'Mr', label: 'Mr' },
                                    { value: 'Mrs', label: 'Mrs' },
                                    { value: 'Ms', label: 'Ms' },
                                ]} onChange={handleNestedChange} disabled={!!verifiedValues}
                                name='formData.familyInfo.partnerDetail.title' value={formData?.familyInfo?.partnerDetail?.title}
                                style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                skeletonClassName='border-r-none' skeletonLoading={isLoading}
                            />

                            <CustomTextField
                                label="First Name" name='formData.familyInfo.partnerDetail.firstName' value={formData?.familyInfo?.partnerDetail?.firstName} onChange={handleNestedChange} disabled={handleDisable('familyInfo.partnerDetail.firstName')}
                                fullWidth className='bg-white' InputProps={{ style: { borderRadius: "0px 4px 4px 0px" } }} skeletonLoading={isLoading}
                            />
                        </div>

                        {familyFields.map((field, index) => (renderFormField({
                            ...field,
                            value: formData?.familyInfo?.partnerDetail?.[field.name],
                            name: `formData.familyInfo.partnerDetail.${field?.name}`,
                            disabled: handleDisable('familyInfo.partnerDetail.' + field?.name),
                            onChange: handleNestedChange
                        }, index, isLoading)))}
                    </div>}

                <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14">
                    {!formData?.familyInfo?.fatherDetail?.isDeceased && <>
                        <h5 className="h4-p col-span-full">Father Details</h5>
                        <div className="flex w-full">
                            <CustomAsyncSelect
                                className='bg-white w-24' label="Title"
                                options={[
                                    { value: 'Dr', label: 'Dr' },
                                    { value: 'Mr', label: 'Mr' },
                                    { value: 'Mrs', label: 'Mrs' },
                                    { value: 'Ms', label: 'Ms' },
                                ]} onChange={handleNestedChange} disabled={!!verifiedValues}
                                name='formData.familyInfo.fatherDetail.title'
                                value={formData?.familyInfo?.fatherDetail?.title}
                                style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                skeletonClassName='rounded-r-none'
                                skeletonLoading={isLoading}
                            />
                            <CustomTextField
                                label="Father First Name" name='formData.familyInfo.fatherDetail.firstName' value={formData?.familyInfo?.fatherDetail?.firstName} onChange={handleNestedChange}
                                fullWidth className='bg-white' InputProps={{ style: { borderRadius: "0px 4px 4px 0px" } }} disabled={handleDisable('familyInfo.fatherDetail.firstName')}
                                skeletonLoading={isLoading}
                            />
                        </div>

                        {familyFields.map((field, index) => (renderFormField({
                            ...field,
                            value: formData?.familyInfo?.fatherDetail?.[field?.name],
                            name: `formData.familyInfo.fatherDetail.${field?.name}`,
                            disabled: handleDisable('familyInfo.fatherDetail.' + field?.name),
                            onChange: handleNestedChange
                        }, index, isLoading)))}
                    </>}

                    {isLoading ?
                        <div className="col-span-full animate-pulse rounded-md bg-muiLight h-11" />
                        : <div className="col-span-full flex items-center">
                            <Checkbox id="father-checkbox" checked={formData?.familyInfo?.fatherDetail?.isDeceased} color="secondary" disabled={handleCheckboxDisabled(verifiedValues?.familyInfo?.fatherDetail)} onChange={handleFatherDeceased} />
                            <label htmlFor="father-checkbox" className="text-s cursor-pointer">Is your father deceased?</label>
                        </div>}
                </div>

                <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14">
                    {!formData?.familyInfo?.motherDetail?.isDeceased && <>
                        <h5 className="h4-p col-span-full">Mother Details</h5>
                        <div className="flex w-full">
                            <CustomAsyncSelect
                                className='bg-white w-24' label="Title"
                                options={[
                                    { value: 'Dr', label: 'Dr' },
                                    { value: 'Mr', label: 'Mr' },
                                    { value: 'Mrs', label: 'Mrs' },
                                    { value: 'Ms', label: 'Ms' },
                                ]} onChange={handleNestedChange}
                                name='formData.familyInfo.motherDetail.title' value={formData?.familyInfo?.motherDetail?.title} disabled={!!verifiedValues}
                                style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                skeletonClassName='rounded-r-none'
                                skeletonLoading={isLoading}
                            />

                            <CustomTextField
                                label="Mother First Name" name='formData.familyInfo.motherDetail.firstName' value={formData?.familyInfo?.motherDetail?.firstName} onChange={handleNestedChange} fullWidth className='bg-white' InputProps={{ style: { borderRadius: "0px 4px 4px 0px" } }} disabled={handleDisable('familyInfo.motherDetail.firstName')} skeletonLoading={isLoading}
                            />
                        </div>

                        {familyFields.map((field, index) => (
                            renderFormField({
                                ...field,
                                value: formData?.familyInfo?.motherDetail?.[field.name],
                                name: `formData.familyInfo.motherDetail.${field?.name}`,
                                required: ['phNo'].includes(field?.name) ? false : field?.required,
                                disabled: handleDisable('familyInfo.motherDetail.' + field?.name),
                                onChange: handleNestedChange
                            }, index, isLoading)
                        ))}
                    </>}
                    {isLoading ?
                        <div className="col-span-full animate-pulse rounded-md bg-muiLight h-11" />
                        : <div className="col-span-full flex items-center">
                            <Checkbox id="mother-checkbox" checked={formData?.familyInfo?.motherDetail?.isDeceased} color="secondary" disabled={!!verifiedValues} onChange={handleMotherDeceased} />
                            <label htmlFor="mother-checkbox" className="text-s cursor-pointer">Is your mother deceased?</label>
                        </div>}
                </div>

                <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14">
                    {isLoading ?
                        <div className="col-span-full animate-pulse rounded-md bg-muiLight h-11" />
                        : <div className="col-span-full flex items-center">
                            <Checkbox id="children-checkbox" checked={formData?.familyInfo?.childrenDetail} color="secondary" onChange={handleChildrenCheckbox} disabled={!!verifiedValues} />
                            <label htmlFor="children-checkbox" className="text-s cursor-pointer">Do you have children?</label>
                        </div>}

                    {formData?.familyInfo?.childrenDetail?.length > 0 && (
                        <AddComponent array={formData?.familyInfo?.childrenDetail} name='formData.familyInfo.children' properties={childrenFields} label='Children' handleChange={handleNestedChange} loading={isLoading} verifiedValues={!!verifiedValues && 'accepted'} />
                    )}
                </div>

                <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 lg:gap-x-14">
                    <h5 className="h4-p col-span-full">Emergency Person Details</h5>
                    <div className="flex w-full">
                        <CustomAsyncSelect
                            className='bg-white w-24' label="Title"
                            options={[
                                { value: 'Dr', label: 'Dr' },
                                { value: 'Mr', label: 'Mr' },
                                { value: 'Mrs', label: 'Mrs' },
                                { value: 'Ms', label: 'Ms' },
                            ]} onChange={handleNestedChange}
                            name='formData.familyInfo.emergencyPersonDetail.title' value={formData?.familyInfo?.emergencyPersonDetail?.title} disabled={!!verifiedValues}
                            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                            skeletonClassName='rounded-r-none'
                            skeletonLoading={isLoading}
                        />

                        <CustomTextField
                            label="First Name" name='formData.familyInfo.emergencyPersonDetail.firstName' value={formData?.familyInfo?.emergencyPersonDetail?.firstName} onChange={handleNestedChange} fullWidth className='bg-white' InputProps={{ style: { borderRadius: "0px 4px 4px 0px" } }} disabled={!!verifiedValues} skeletonLoading={isLoading}
                        />
                    </div>

                    {EmergencyPersonFields.map((field, index) => (
                        renderFormField({ ...field, disabled: !!verifiedValues, onChange: handleNestedChange }, index, isLoading)
                    ))}
                </div>
            </div>
            <BottomTile id={id} clearForm={clearForm} steps={steps} />
        </form>
    );
};

export default PersonalDetails;