import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { AccountBalance, AccountCircle, Mail, Phone, School } from '@mui/icons-material';

import useSnackbar from '../../hooks/useSnackbar';

import { CloseButton } from '../mui/button/Button';
import SkeletonText from '../mui/text/SkeltonText';

const ProfileMenu = ({ employeeData, isLoading }) => {
    const navigate = useNavigate();
    const { handleSnackbarOpen } = useSnackbar()

    const [ popupOpen, setPopupOpen] = useState(false);

    const handleOpenPopup = () => setPopupOpen(true);
    const handleClosePopup = () => setPopupOpen(false);
    const togglePopup = () => setPopupOpen(!popupOpen);

    const handleLogout = () => {
        handleSnackbarOpen('Logout successfully', 'success', null, 'bottom', 'left');
        navigate('/login');
    };

    const menuOptions = [
        { label: employeeData?.employeeId, icon: <School className='text-purple' /> },
        { label: employeeData?.emailId, icon: <Mail className='text-signal' /> },
        { label: employeeData?.phNo, icon: <Phone className='text-green' /> },
        { label: employeeData?.designation, icon: <AccountCircle className='text-grey' /> },
        { label: employeeData?.departments?.join(', '), icon: <AccountBalance className='text-purple' /> },
    ];
    const fullName = `${employeeData?.firstName} ${employeeData?.middleName} ${employeeData?.lastName}`;

    return (
        <div className="relative flex w-full z-50" onMouseEnter={handleOpenPopup} onMouseLeave={handleClosePopup} onClick={togglePopup}>
            <IconButton disableTouchRipple>
                <Avatar style={{height:32, width:32}} src={employeeData?.photoURL} alt={fullName}/>
            </IconButton>

            {popupOpen && <div className='absolute top-11 right-0 flex flex-col bg-white p-4 sm:p-6 rounded-md drop-shadow w-full min-w-[270px] xs:min-w-96 max-w-96 transition-all ease-in-out duration-300 z-50'>
                <div className='flex space-x-3 items-center'>
                    <Avatar alt={fullName} src={employeeData?.photoURL} style={{ borderRadius: 3 }} />
                    <p className='flex flex-col truncate'>
                        <span className='h5-p font-semibold'>{fullName}</span>
                        <span className='text-s text-xs'>{employeeData?.collegeShortName}</span>
                    </p>
                </div>

                <hr className='bg-slate-400 border-0.5 mt-3 mb-4' />

                <div className='flex flex-col space-y-4 w-full ml-1 sm:ml-2'>
                    {menuOptions?.map((option, index) => (
                        <div key={index} className='flex items-center space-x-3'>
                            {option?.icon}
                            <SkeletonText width={300} loading={isLoading}>
                                <p className='text-s font-normal'>{option?.label}</p>
                            </SkeletonText>
                        </div>
                    ))}
                </div>

                <hr className='bg-slate-400 border-0.5 my-4' />
                
                <CloseButton variant='contained' text="Logout" onClick={handleLogout} />
            </div>}
        </div>
    );
};

export default ProfileMenu;