import React from 'react';
import Button from '@mui/material/Button';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const CustomButton = ({ additionalClass, buttonName, ...props }) => {
    const { className, onClick, variant, color, type, startIcon, endIcon, style, size, children, disabled, disabledElevation } = props;
    return (
        <Button
            className={`${className} ${additionalClass}`}
            onClick={onClick}
            variant={variant}
            color={color}
            type={type}
            startIcon={startIcon}
            endIcon={endIcon}
            size={size}
            style={disabled ? {} : style}
            disabled={disabled}
            disableElevation={disabledElevation}
            {...props}
        >
            {buttonName || children}
        </Button>
    );
};

export const CrossButton = ({ onClose, className = "w-fit h-fit", size = '24' }) => (
    <button className={className} onClick={onClose}>
        <HighlightOffIcon className='text-signal' sx={{ fontSize: size }} />
    </button>
);

export const CloseButton = ({ className = '', variant = "outlined", size = "medium", onClick, type, text, fullWidth = false, disabledElevation = true, capitalize = true, ...props }) => (
    <CustomButton
        variant={variant} color="error" type={type ? type : "button"} fullWidth={fullWidth} size={size}
        additionalClass={className} onClick={onClick} disabledElevation={disabledElevation} className='min-w-[10rem]' sx={{ paddingX: 3 }} style={{ textTransform: capitalize ? 'uppercase' : 'none' }} {...props}>
        {text ? text : 'Discard'}
    </CustomButton>
);

export const ConfirmButton = ({ className = '', fullWidth, onClick, type, text = 'Confirm', size = "medium", startIcon, endIcon, disabledElevation = true, capitalize = true, ...props }) => (
    <CustomButton additionalClass={className} className='min-w-[10rem]' fullWidth={fullWidth}
        style={{ zIndex: 1, textTransform: capitalize ? 'uppercase' : 'none' }} color="success" variant="contained" size={size} disabledElevation={disabledElevation}
        type={type} startIcon={startIcon} onClick={onClick} endIcon={endIcon} {...props} >
        {text}
    </CustomButton>
);

export const PurpleButton = ({ className = '', onClick, type, startIcon, endIcon, text = '', disabled, disabledElevation = true, size = "medium", capitalize = true, sx, ...props }) => (
    <CustomButton additionalClass={className} className='min-w-40'
        sx={{ zIndex: 1, backgroundColor: disabled ? '#E0E0E0' : '#5B21B6', color: disabled ? '#B0B0B0' : '#FFFFFF', textTransform: capitalize ? 'uppercase' : 'none', '&:hover': { backgroundColor: disabled ? '#B0B0B0' : '#4A1A94' }, ...sx }}
        variant="contained" disabled={disabled} size={size}
        type={type} startIcon={startIcon} onClick={onClick} endIcon={endIcon} disabledElevation={disabledElevation} {...props} >
        {text}
    </CustomButton>
);

export const LightButton = ({ className = '', onClick, type, startIcon, endIcon, text = '', disabled, disabledElevation = true, size = "medium", capitalize = true, ...props }) => (
    <CustomButton additionalClass={className} className='min-w-40'
        sx={{ zIndex: 1, backgroundColor: disabled ? '#E0E0E0' : '#F0E5FC', color: disabled ? '#B0B0B0' : '#5B21B6', textTransform: capitalize ? 'uppercase' : 'none', '&:hover': { backgroundColor: disabled ? '#E0E0E0' : '#E7D8F8' } }}
        variant="contained" disabled={disabled} size={size}
        type={type} startIcon={startIcon} onClick={onClick} endIcon={endIcon} disabledElevation={disabledElevation} {...props} >
        {text}
    </CustomButton>
);

export const BlackButton = ({ className = '', onClick, type, startIcon, endIcon, size = "medium", text = '', disabled, disabledElevation = true, ...props }) => (
    <CustomButton additionalClass={className} className='w-fit min-w-[10rem]'
        sx={{
            zIndex: 1, backgroundColor: disabled ? '#E0E0E0' : '#000000',
            color: disabled ? '#B0B0B0' : '#FFFFFF',
            '&:hover': { backgroundColor: disabled ? '#E0E0E0' : 'transparent' },
            paddingX: 3, paddingY: 1
        }} disabled={disabled} size={size}
        type={type} startIcon={startIcon} onClick={onClick} endIcon={endIcon} disabledElevation={disabledElevation} {...props} >
        {text}
    </CustomButton>
);

export const DownloadButton = ({ className = '', onClick, text }) => (
    <CustomButton
        className="py-3 min-w-[10rem] whitespace-nowrap"
        additionalClass={className}
        onClick={onClick}
        variant="contained"
        startIcon={<FileDownloadOutlinedIcon style={{ color: 'white' }} />}
        style={{ zIndex: 1, backgroundColor: "#010851", color: "white", borderRadius: 8 }} >
        {`Download All ${text}`}
    </CustomButton>
);

export const UploadButton = ({ className, onClick, text, newText, ...props }) => (
    <CustomButton
        className="py-3 min-w-[10rem]"
        additionalClass={className}
        onClick={onClick}
        variant="contained"
        startIcon={<CloudUploadOutlinedIcon style={{ color: 'white' }} />}
        style={{ zIndex: 1, backgroundColor: "#010851", color: "white", borderRadius: 8 }}
        {...props} >
        {newText ? newText : `Upload New ${text}`}
    </CustomButton>
);

export const GhostButton = ({ className = '', onClick, type, startIcon, endIcon, text = '', disabled, disabledElevation = true, size = "medium", capitalize = true, ...props }) => (
    <CustomButton additionalClass={className} className='min-w-40 '
        style={{ zIndex: 1, color: disabled ? '#B0B0B0' : '#FFFFFF', textTransform: capitalize ? 'uppercase' : 'none' }}
        disabled={disabled} size={size}
        type={type} startIcon={startIcon} onClick={onClick} endIcon={endIcon} disabledElevation={disabledElevation} {...props} sx={{
            paddingX: 3, '&:hover': {
                backgroundColor: '#0D0E21',
            },
        }} >
        {text}
    </CustomButton>
);

export const HoverGradientButton = ({ children, ...props }) => {
    return (
        <a href="#_" className="relative inline-flex items-center justify-center px-6 py-3 overflow-hidden font-bold text-white rounded-md shadow-2xl group" {...props}>
            <span className="absolute inset-0 w-full h-full transition duration-300 ease-out opacity-0 bg-gradient-to-br from-pink-600 via-purple-700 to-blue-400 group-hover:opacity-100"></span>
            <span className="absolute top-0 left-0 w-full bg-gradient-to-b from-white to-transparent opacity-5 h-1/3"></span>
            <span className="absolute bottom-0 left-0 w-full h-1/3 bg-gradient-to-t from-white to-transparent opacity-5"></span>
            <span className="absolute bottom-0 left-0 w-4 h-full bg-gradient-to-r from-white to-transparent opacity-5"></span>
            <span className="absolute bottom-0 right-0 w-4 h-full bg-gradient-to-l from-white to-transparent opacity-5"></span>
            <span className="absolute inset-0 w-full h-full border border-white rounded-md opacity-10"></span>
            <span className="absolute w-0 h-0 transition-all duration-300 ease-out bg-white rounded-full group-hover:w-56 group-hover:h-56 opacity-5"></span>
            <span className="relative">{children}</span>
        </a>
    );
}

export const GradientButton = ({ children, className, text, ...props }) => (
    <button className={`btn-gradient-border ${className}`} {...props}>
        {text && text}{children}
    </button>
);

export const GradientHoverButton = ({ children, className, text, ...props }) => (
    <button className={`glow-on-hover py-3 px-8 flex flex-col ${className}`} {...props}>
        {text && text}{children}
    </button>
);

export const PingWrapper = ({ children }) => {
    return (
        <div className='relative inline-flex'>
            {children}
            <span className="absolute -top-1 -right-1 flex h-3 w-3 z-10">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
            </span>
        </div>
    )
}
