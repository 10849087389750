import React from "react";
import { Dialog } from "@mui/material";
import useFormOperations from "../../../../hooks/useFormOperations";
import useGetData from "../../../../hooks/useGetData";
import { CloseButton, ConfirmButton } from "../../../mui/button/Button";
import { CustomAsyncSelect, CustomAutoComplete, CustomTextField, CustomYearPicker } from "../../../mui/Input";

const PromoteRecruitDialog = ({ id, onClose, onConfirm }) => {
    const { data: recruitData, isLoading } = useGetData(`/api/employee/hr/recruits/getRecruitPromotionDetails/${id}`);

    const { formData, handleChange, handleSubmit } = useFormOperations(recruitData, onConfirm, "", recruitData);

    const emailFormats = formData?.emailFormats?.map(email => ({ value: email, label: email })) || [];
    const departments = formData?.departments?.map(({ _id, name }) => ({ value: _id, label: name })) || [];

    const designations = formData?.designations?.map(designation => ({ value: designation, label: designation })) || [];
    const posts = formData?.posts?.map(post => ({ value: post, label: post })) || [];

    return (
        <Dialog open={true} onClose={onClose}>
            <form onSubmit={handleSubmit} className="flex flex-col p-5 gap-5 w-full sm:w-[550px] bg-white rounded-lg h-full no-scrollbar">

                <div className="flex sm:w-[30rem] gap-5">
                    <img className="col-span-2 w-12 h-12" alt="" loading="eager" src='/icons/tabs/Edit.svg' />
                    <div className="flex flex-col" >
                        <h2 className="h4-p">Promote Recruit</h2>
                        <p className="text-s">Are you sure you want to promote this Recruit to current Employee?</p>
                    </div>
                </div>

                <div className="flex flex-col gap-4 w-full max-h-[500px] max-w-[500px] overflow-y-auto py-2 no-scrollbar">
                    <CustomTextField label='Name' name='name' value={formData?.name} onChange={handleChange} skeletonLoading={isLoading} disabled required={false} skeletonClassName='min-h-14' />
                    <CustomTextField label='Email ID' name='emailId' value={formData?.emailId} onChange={handleChange} skeletonLoading={isLoading} disabled required={false} skeletonClassName='min-h-14' />
                    <CustomTextField label='Default Password' name='password' value={formData?.password} onChange={handleChange} skeletonLoading={isLoading} skeletonClassName='min-h-14' />
                    <CustomYearPicker label='Joining Date' name='joiningDate' value={formData?.joiningDate} onChange={handleChange} skeletonLoading={isLoading} skeletonClassName='min-h-14' />

                    <CustomAsyncSelect label='Post'
                        name='currentPost' value={formData?.currentPost}
                        onChange={handleChange} options={posts} loading={isLoading}
                        skeletonLoading={isLoading} skeletonClassName='min-h-14' />
                    <CustomAsyncSelect label='Designation'
                        name='currentDesignation' value={formData?.currentDesignation}
                        onChange={handleChange} options={designations} loading={isLoading}
                        skeletonLoading={isLoading} skeletonClassName='min-h-14' />

                    {(recruitData?.officialEmailId || isLoading) && <CustomAutoComplete
                        label='Official Email ID'
                        placeholder="Select an email ID format"
                        name='officialEmailId'
                        value={formData?.officialEmailId || []}
                        options={emailFormats}
                        onChange={handleChange}
                        allowCustom={true}
                        skeletonLoading={isLoading}
                        skeletonClassName='min-h-14'
                        required={true}
                        multiple={false}
                    />}
                    <CustomAutoComplete
                        label='Departments'
                        name='currentDepartments'
                        value={formData?.currentDepartments}
                        options={departments}
                        onChange={handleChange}
                        skeletonLoading={isLoading}
                        skeletonClassName='min-h-14'
                        multiple={true}
                        required={true}
                    />

                    <CustomTextField multiline rows={4} label='Remarks' name='remark' value={formData?.remark} onChange={handleChange} required={false} skeletonLoading={isLoading} skeletonClassName='min-h-[126px]' />
                </div>

                <div className="flex sm:flex-row justify-between gap-4">
                    <CloseButton onClick={onClose} text='Cancel' fullWidth />
                    <ConfirmButton text="Confirm" type='submit' fullWidth disabled={isLoading} />
                </div>
            </form>
        </Dialog>
    )
}

export default PromoteRecruitDialog;