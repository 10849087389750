import { Link } from 'react-router-dom';

import useGetClientData from '../../hooks/useGetClientData';

import ReachLogo from '../../assets/logo/ReachLogo.svg'
import Fields from './Fields';

const Form = () => {
    const { organizationName, logoThumbnailURL } = useGetClientData();

    return (
        <div className="col-span-full flex flex-col lg:col-span-6 items-center text-center w-full px-5 sm:px-10 py-10 lg:py-14 gap-10">

            <header className='flex flex-col items-center select-none gap-2'>
                <img draggable={false} className='block lg:hidden h-20' src={logoThumbnailURL} alt={organizationName} />
                <img draggable={false} className='w-48 sm:w-52 h-20' src={ReachLogo} alt="REACH_Logo" />
                <p className='text-light text-xs sm:text-sm font-medium text-center max-w-xl w-full'>One Organization, One Platform</p>
            </header>

            <Fields/>

            <div className='lg:hidden flex flex-col items-center gap-2 text-sm text-medium text-slate-600'>
                <div className='flex flex-col xs:flex-row gap-3 xs:gap-5'>
                    <Link className="hover:text-regal-purple-700" to='/privacypolicy'>Privacy Policy</Link>
                    <span className='hidden xs:flex'>|</span>
                    <Link className=" hover:text-regal-purple-700" to='/termsandconditions'>Terms and Conditions</Link>
                </div>
                <span className='text-slate-400'>© {new Date().getFullYear()} Byteswrite. All Rights Reserved</span>
            </div>
        </div>
    )
}

export default Form;